import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/gamme',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'catalogIndex', 
        meta: { 
          slug: 'gamme', 
          load: 'PageSingle', 
          prevnext: 'btn' 
        },  // force param !
        component: () => import('pages/PageSingle.vue')
      },
      { 
        name: 'catalogSingle', 
        path: ':slug', 
        meta: { 
          prevnext: 'parallax' 
        },  // force param !
        component: () => import('pages/CatalogSingle.vue')
      },
      { 
        path: ':cat/:slug', 
        name: 'CollectionSingle', 
        meta: { 
          prevnext: 'parallax' 
        },  // force param !
        component: () => import('pages/CollectionSingle.vue')
      },
    ]
  },
  {
    path: '/plantes',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'ProductsIndex', 
        meta: { 
          slug: 'plantes', 
          load: 'PageSingle' 
        },  // force param !
        component: () => import('pages/PageSingle.vue')
      },
      { 
        name: 'ProductSingle', 
        path: ':slug', 
        meta: { 
          prevnext: 'btn' 
        },  // force param !
        component: () => import('pages/ProductSingle.vue')
      }
    ]
  },
  {
    path: '/blog',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        meta: { 
          slug: 'blog', 
          load: 'PageSingle',
          prevnext: 'btn' 
        },  // force param !
        name: 'BlogIndex', 
        component: () => import('pages/PageSingle.vue')
      },
      { 
        path: 'cat', 
        redirect: { name: 'BlogIndex' }
      },
      { 
        path: 'cat/:slug', 
        name: 'CategorySingle', 
        component: () => import('src/pages/CategorySingle.vue'),
        meta: { 
          load: 'CategorySingle',
          index: false, 
          page: 1 
        },
        children: [
          {
            path: ':page', 
            name: 'CategorySinglePagination', 
            component: () => import('src/pages/CategorySingle.vue'),
            meta: { 
              load: 'CategorySingle',
              index: false 
            },
          }
        ]
      }
    ]
  },
  {
    path: '/:cat/:slug',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'PostSingle', 
        component: () => import('pages/PostSingle.vue'),
        meta: { 
          index: false, 
          page: 1,
          prevnext: 'parallax'
        }
      }
    ]
  },
  {
    path: '/producteurs',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'producerIndex', 
        meta: { 
          slug: 'producteurs', 
          load: 'PageSingle',
          prevnext: 'btn'
        },  // force param !
        component: () => import('pages/PageSingle.vue')
      },
      { 
        name: 'ProducerSingle', 
        path: ':slug', 
        meta: { 
          prevnext: 'btn'
        },  // force param !
        component: () => import('pages/ProducerSingle.vue')
      }
    ]
  },
  {
    path: '/landing-page/:slug',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'LandingPageSingle', 
        component: () => import('pages/LandingPageSingle.vue'),
        meta: { 
          slug: 'landing-page',
          index: false, 
          page: 1,
          load: 'LandingPageSingle'
        }
      }
    ]
  },
  {
    path: '/contact',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'ContactPage', 
        component: () => import('pages/ContactPage.vue') 
      }
    ]
  },
  {
    path: '/404',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { path: '', name: 'ErrorPage', component: () => import('pages/ErrorPage.vue') }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayoutV2.vue'),
    children: [
      { 
        path: '', 
        name: 'FrontPage', 
        component: () => import('pages/FrontPage.vue')
      },
      { 
        path: ':slug', 
        sensitive: true,
        name: 'PageSingle', 
        meta: { 
          load: 'PageSingle',
          prevnext: 'btn'
        },  // force param !
        component: () => import('pages/PageSingle.vue')
      }
    ]
  },

  // // Always leave this as last one,
  // // but you can also remove it
  // {
  //   path: '/:catchAll(.*)*',
  //   // component: () => import('pages/ErrorNotFound.vue'),
  //   component: () => import('pages/PageSingle.vue'),
  // },
];

export default routes;
